import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickHelpComponent } from './quick-help/quick-help.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeComponent } from './date-range/date-range.component';
import { NbDatepickerModule, NbIconModule, NbTooltipModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { HelpPanelComponent } from './help-panel/help-panel.component';




@NgModule({
  declarations: [DateRangeComponent,QuickHelpComponent, HelpPanelComponent],
  imports: [
    CommonModule,
    NbDatepickerModule.forRoot(),
    FormsModule,
    NbIconModule,
    NgbModule,
    NbTooltipModule

  ],
  exports: [DateRangeComponent,QuickHelpComponent,HelpPanelComponent]
})
export class SharedComponentsModule {

  static forRoot():ModuleWithProviders<SharedComponentsModule>
  {
    return {
      ngModule:SharedComponentsModule,

    };
  }


 }
