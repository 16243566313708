import { HttpClient } from '@angular/common/http';
import { AuthInfoModel } from './../Models/auth-info.model';
import { Observable } from 'rxjs';
import { LoginModel, LoginResponseModel } from './../Models/login.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private storageName = '2ffauthinfo';
  headerName = 'QuantumXauth';

  constructor(private router: Router,
    private httpService: HttpClient) { }
  redirectToLogin(): void {
    this.router.navigateByUrl('/auth/login');
  }
  isLoged(): boolean {
    if (this.getToken()) return true; return false;
  }
  login(model: LoginModel): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.httpService.post<LoginResponseModel>('/api/auth/login', model)
        .subscribe(s => {
          if (s.success) {
            localStorage.setItem(this.storageName, JSON.stringify({
              token: s.token,
              expiration: s.expiration
            } as AuthInfoModel));
            this.router.navigateByUrl('/');
          }
          observer.next(s.success);
        }, e => observer.next(false));
    });
  }


  getToken(): string | undefined {
    var info = (JSON.parse(localStorage.getItem(this.storageName) ?? '{}') as AuthInfoModel);
    if (!info || info.expiration < new Date(Date.now()))
      return undefined;
    return info.token;
  }
}
